.acc__title {
  font-size: 20px;
  font-family: boldFont;
  color: rgba(23, 23, 37, 0.7);
}

.role__continer {
  background-color: #fff;
}

.MuiPaper-elevation1 {
  box-shadow: 1px 4px 10px #15223234 !important;
}

.section__title {
  color: #444;
  font-size: 20px;
}

tbody .modal__table:hover {
  background-color: #fff !important;
}

.fixedBody {
  max-height: 60vh;
}

.carbon__buttoncontainer {
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.filter__input {
  padding: 8px;
  border: 1px solid #b3cdff80 !important;
  padding-left: 12px;
  border-radius: 6px;
  font-size: 18px;
  font-family: semiFont;
  color: #565656;
}

.filter-search-icon {
  position: absolute;
  right: 6px;
  bottom: 11px;
  width: 30px;
  z-index: 10;
}
