/* .userView__container {
  background-color: #fff;
} */

.tabItem {
  width: 30%;
  background-color: #fff;
  padding: 15px;
  font-family: semiFont;
  color: #525252;
  text-align: center;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
  border-top: 3px solid #fff;
}

.classtabItem {
  width: 30%;
  background-color: #e0e0e0;
  padding: 15px;
  font-family: semiFont;
  color: #525252;
  text-align: center;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
  border-top: 3px solid #e0e0e0;
}

.MuiTab-root {
  padding: 20px !important;
  background-color: #e0e0e0 !important;
}

.MuiTab-root.Mui-selected {
  background-color: #fff !important;
}

.MuiTab-wrapper {
  font-size: 18px !important;
  font-family: semiFont !important;
  text-transform: initial !important;
}

.tabItem--active {
  width: 30%;
  background-color: #e0e0e0;
  padding: 15px;
  font-family: semiFont;
  color: #161616;
  text-align: center;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
  border-top: 3px solid #1565d8;
}
.classtabItem--active {
  width: 30%;
  background-color: #fff;
  padding: 15px;
  font-family: semiFont;
  color: #161616;
  text-align: center;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
  border-top: 3px solid #1565d8;
}
