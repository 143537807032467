.activation__container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.activation__title {
  font-size: 18px;
  font-family: semiFont;
  color: #171725;
  text-align: center;
}

.header__span {
  color: #1565d8;
}
