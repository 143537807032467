/* input[type="checkbox"]:checked {
    background-color: #2ecc71;
    border: 1px solid #2ecc71;
} */

.appointments__booking {
  padding: 30px 16px !important;
}

.appointments__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.appointments__book {
  flex: 1;
}

.appointments__container:last-of-type {
  margin-bottom: 0;
}

.appointments__time,
.appointments__unavailable {
  flex: 5;
}

.appointments__title {
  margin-top: -2px;
  margin-left: 15px;
}

.appoitment__items {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.appoitment__items .admin__Addproduct--dropdown {
  width: 145px !important;
}
.appoitment__items #eventsDropdown {
  width: 245px !important;
}

.appoitment__items .MuiIconButton-root {
  width: 45px;
  height: 45px;
}
.appoitment__items .admin__Addproduct--dropdown div div div input:focus {
  border: none !important;
}

.holidays__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.holiday__item {
  width: 50%;
  padding: 10px 0;
}

.holiday__date {
  color: #5a7184;
}

.holidays__container .MuiIconButton-root {
  width: 45px;
  height: 45px;
}

@media screen and (max-width: 1162px) {
  .appointments__timeSection {
    display: flex;
    flex-direction: column !important;
  }

  .appointments__time,
  .appointments__unavailable {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .appoitment__items {
    flex-wrap: wrap;
  }

  .appoitment__items {
    position: relative;
  }
  /* .appoitment__items .MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
  } */
}
