.Dashboard_Container {
  padding: 20px;
}

.main__container {
  width: 100%;

  background-color: #fff;
}

.image_container {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: left;
  text-align: center;
}

.primary__btn--disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  text-transform: inherit !important;
  font-size: 18px !important;
  font-family: semiFont !important;
}

.tab__items {
  color: #1565d8 !important;
  padding: 10px 15px !important;
  text-transform: initial !important;
  font-size: 17px !important;
  transition: all 0.3s !important;
}

.tab__container > Button:not(:first-of-type) {
  margin-left: 20px !important;
}

.tab__items--active {
  background-color: #1565d8 !important;
  color: #fff !important;
  padding: 10px 15px !important;
  text-transform: initial !important;
  font-size: 17px !important;
  transition: all 0.3s !important;
}

.active__container {
  border-radius: 8px;
  box-shadow: 1px 4px 10px #15223234;
  padding: 15px;
}
.signup__buttondisabled {
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  font-family: mediumFont !important;

  padding: 8px 25px !important;
}

.profile_image {
  width: 108px;
  height: 108px;
}
.change__button {
  color: #4059e2 !important;
  font-family: mediumFont !important;
  /* margin-top: 15px !important; */
}

.image__container {
  display: flex;
  align-items: center;
}
.remove__button {
  color: #45454590 !important;
  padding: 10px 15px !important;
  text-transform: initial !important;
  font-size: 17px !important;
  transition: all 0.3s !important;
  font-family: boldFont !important;
}

.avator__userName {
  /* font-size: 18px !important; */
  color: #454545;
}
.Heading__Text {
  color: #4059e2;
  font-family: boldFont;
}
.addField__button {
  width: 100% !important;
  text-align: left !important;
  justify-content: left !important;
}

@media only screen and (max-width: 767px) {
  .align-767 {
    justify-content: center !important;
  }
  .field-header {
    text-align: center !important;
  }
  .image-error-position {
    position: absolute;
    width: 100%;

    left: 20%;
  }
}

@media only screen and (max-width: 550px) {
  .tab__container {
    flex-direction: column;
    align-items: center !important;
  }

  .tab__container > Button {
    margin-top: 8px;
  }

  .tab__container > Button:not(:first-of-type) {
    margin-left: 0 !important;
  }

  .tab__items,
  .tab__items--active {
    width: 230px;
  }
}

@media only screen and (max-width: 346px) {
  .custom-personal--input {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
  .dashboard-errorPosition {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dashboard-errorPosition2 {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 300px) {
  .custom-personal--input {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
  .dashboard-errorPosition {
    font-size: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dashboard-errorPosition2 {
    font-size: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.email-verify {
  position: absolute;
  right: 5px;
  bottom: 19%;
  color: #03a33b;
  font-family: regularFont;
}
