.primary-bg {
  background-color: #1565d8;
}

tbody tr td {
  word-wrap: break-word !important;
}

.center__items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carbon__btn {
  flex: 1;
  /* width: 50% !important; */
  padding: 20px !important;
  border-radius: 0 !important;
  text-transform: initial !important;
  justify-content: flex-start !important;
  font-size: 18px !important;
  font-family: semiFont !important;
  box-shadow: none !important;
}

.carbon__secondarybtn {
  background-color: #4c4c4c !important;
}

.carbon__primarybtn {
  background-color: #1565d8 !important;
}

.carbon__deletebtn {
  background-color: #da1e28 !important;
}

.carbon__text {
  margin-left: 10px !important;
  /* margin-bottom: 5px !important; */
}

.primary__btn {
  background-color: #1565d8 !important;
  text-transform: initial !important;
  font-size: 18px !important;
  font-family: semiFont !important;
}
.secondary__btn {
  background-color: #fff !important;
  text-transform: initial !important;
  font-size: 18px !important;
  font-family: semiFont !important;
}
.regularFont {
  font-family: regularFont;
}

.mediumFont {
  font-family: mediumFont;
}

.boldFont {
  font-family: boldFont;
}

.semiFont {
  font-family: semiFont;
}

.italicFont {
  font-family: italicFont;
}

.hkRegularFont {
  font-family: hkRegularFont;
}

.hkBoldFont {
  font-family: hkBoldFont;
}
