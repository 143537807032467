.report__container {
  width: 100%;
}
.filter-section {
  background-color: white;
}
.search-inputs {
  max-width: 40%;
  position: relative;
}
.search-icon {
  position: absolute;
  right: 1%;
  bottom: 27%;
  width: 30px;
  height: 20px;
  z-index: 10;
}

.item__title {
  font-size: 19px;
  font-family: semiFont;
  color: #292828;
}
.item__subTitle {
  font-size: 17px;
  font-family: mediumFont;
  color: #4d4d4d;
  word-wrap: break-word;
}

.comments_title,
.comments_desc {
  font-size: 17px;
  color: #656565;
  font-family: mediumFont;
  text-transform: initial;
}

.comments_title {
  font-family: semiFont !important;
  font-size: 18px;
  color: #363636;
}

.comments_title::first-letter,
.comments_desc::first-letter {
  text-transform: uppercase;
}
/* .search-field:focus {
    box-shadow: none !important;
  } */
.dropdown-periods {
  width: 200px !important ;
  z-index: 50;
}

.search__inputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  margin-left: 15px;
}

.dropdown-periods-custom {
  width: 200px !important ;
  z-index: 52;
}
.filter-date {
  width: 200px !important;
}

.report__details {
  width: 100%;
}

.report__header {
  font-family: semiFont;
  font-size: 20px;
}

.input__row {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.input__row:not(:first-of-type) {
  margin-top: 10px;
}

.inputBox__report input {
  padding: 15px;
  border: 1px solid #c3cad9;
  border-radius: 8px;
  width: 300px;
  /* margin-top: 10px; */
  color: #183b56;
  font-size: 17px;
  font-family: mediumFont;
  transition: all 0.3s;
  outline: none;
}

.positioned__error {
  position: absolute;
  left: 0;
  top: 0;
}

.inputBox__report {
  width: 300px;
}

.file__container label {
  position: relative;
  z-index: 0;
  display: inline-block;
  /* width: 100%; */
  background: #fff;
  cursor: pointer;
  color: #1565d8;
  padding: 13px 25px;
  border-radius: 6px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  /* text-transform: uppercase; */
  font-size: 18px;
  text-align: center;
}

.file__container .input__upload {
  display: inline-block;
  position: absolute;
  z-index: 1;

  width: 50%;
  padding: 13px 25px;
  border-radius: 6px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.uploaded__items {
  width: 49%;
}

.button__row {
  gap: 10px;
}

@media screen and (max-width: 991px) {
  .inputBox__report {
    width: 100%;
  }

  .file__container {
    width: 50%;
  }
}

@media only screen and (max-width: 622px) {
}
.Filter__button {
  background-color: #f8f8f8 !important;
  border: 1px solid #b3cdff !important;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 10px #00000017 !important;
  color: #000000 !important;
  font-family: mediumFont !important;
}
.filter-text {
  color: #393939;
  font-family: mediumFont;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 10px;
}
.dropdown-status {
  width: 200px !important ;
}
.row-filter {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* #dropdown-transition {
    transition: all 0.5s ease-out;
  }
  
  #searchStatic {
    position: relative;
    transition: all 1s ease-out;
  }
  
  #searchTransition {
    position: absolute;
  
    left: 0;
    top: 50px;
  } */
.usertable {
  border: "1px solid #B3CDFF " !important;
}

#rd p {
  display: none;
}
.hided-row {
  display: revert !important;
}
@media only screen and (min-width: 993px) {
  .hided-row {
    display: none !important;
  }
}

@media only screen and (max-width: 440px) {
  .testtype-col {
    display: none;
  }
  .hided-col-testtype {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  #rd p {
    display: inline;
  }
  .lastupdated-col {
    display: none;
  }
  .testcenter-col {
    display: none;
  }
  .hided-col-testtype {
    display: none;
  }

  .report__details {
    width: auto;
  }
}
.table-header {
  color: #818181;
  font-family: regularFont;
  font-size: 14px;
}
.table-row-data {
  color: #000000;
  font-family: mediumFont;
  padding: 20px 10px !important;
  text-transform: capitalize !important;
}
/* .table-row-hover:hover {
  } */
/* .toggle-row-icon {
    color: ;
  } */
.table-hide-list {
  list-style-type: none;
}

@media only screen and (max-width: 768px) {
  .filter_input-small {
    margin-top: 10px !important;
  }

  .search__inputBox {
    flex-direction: column;
    /* justify-content: center; */
  }

  .search__testcontainer {
    margin-right: 0 !important;
  }
}
.admin-reports-row-hover:hover {
  background-color: #00000017 !important;
}
/* report view */

.admin-reportview-span {
  display: block !important;
}
.admin-reportview-btns {
  padding: 0;
}
.admin-reportview-btns #admin-reportview-bill-btn,
.admin-reportview-btns #admin-reportview-download-btn {
  background-color: #f8f8f8;
  border: "1px solid #B3CDFF" !important;
  padding: 10px 20px !important;
  width: 80%;
}
.admin-reportview-dropdown-status {
  height: 20%;
}

#admin-reportview-upload #admin-reportview-upload-btn {
  width: 100%;
}
.covid-status-label {
  color: #39393967;

  font-size: 14px;
  font-family: mediumFont;
}
.admin-reportview-heading-text {
  color: #292828;

  font-size: 17px;
  font-family: semiFont;
}
.admin-reportview-sub-testid {
  color: #393939;
  font-size: 16px;
  font-family: demiFont;
}
.admin-reportview-sub-text {
  color: #656565;
  font-size: 16px;
  font-family: mediumFont;
  text-transform: capitalize;
}
.admin-reportview-sub-address {
  color: #818181;
  font-size: 14px;
  font-family: mediumFont;
  text-transform: capitalize;
}

.admin-reportview-send-btn {
  background-color: #4059e2 !important;
}
.admin-reportview-comments {
  background-color: white !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 12%);
}
.admin-reportview-comments-box {
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 12%);
}

.admin-billview-header {
  padding: 15px 0px;
  border-bottom: 1px solid #b3cdff !important;
}
.bill-title {
  color: #000000;
  font-size: 20px;
  font-family: mediumFont;
}
.bill-title2 {
  color: #000000;
  font-size: 30px;
  font-family: demiFont;
}
.billview-title-Text {
  color: #39393980;
  font-size: 15px;
  font-family: mediumFont;
  display: block;
  margin-top: 14px;
}

.billview-title-Text-billid {
  color: #000000;
  font-size: 28px;
  font-family: mediumFont;
  display: block;
}
.billview-title-Text-status {
  color: #03a33b;
  font-size: 20px;
  font-family: mediumFont;
  display: block;
}
.bill-title-name {
  color: #000000;
  font-size: 21px;
  font-family: mediumFont;
  display: block;
  text-transform: capitalize;
}
.bill-title-address {
  color: #000000;
  font-size: 16px;
  font-family: mediumFont;
  display: block;
}
.admin-bill-table-header {
  color: #39393980 !important;
  font-size: 12px !important;
  font-family: regularFont !important;
}
.admin-bill-item-text {
  color: #000000 !important;
  font-size: 16px !important;
  font-family: mediumFont !important;
}
.loader-position {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
@media only screen and (max-width: 992px) {
  .col-alignment {
    text-align: center;
    margin-top: 25px;
  }
}
.custom-date-input {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  vertical-align: middle;
}
.custom-date-input label {
  margin-bottom: none !important;
  margin: 0px 10px;
  font-family: mediumFont;
}
.filter_container-admin {
  border: 1px solid #e0e0e0;
  background-color: white;
}
#filter-period {
  z-index: 55 !important;
}
.Comments-section-container {
  width: 100% !important;
}
